import React, { useEffect } from "react";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import { makeStyles } from "@material-ui/core";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import InventoryStocks from "./InventoryStocks";
import InventoryFilterDialog from "./InventoryFilterDialog";
import InventoryStockMoves from "./InventoryStockMoves";
import { Add, ArrowBack } from "@material-ui/icons";
import { useState } from "react";
import Select from "../../styled/generic/Select";
import { useDispatch } from "react-redux";
import { useDebounce } from "react-use";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import WarehouseConfigDialog from "./WarehouseConfigDialog";
import StocksConfigDrawer from "./StocksConfigDrawer";
import StocksMoveConfigDrawer from "./StocksMoveConfigDrawer";
import { 
  LocalShipping, 
  Store, 
  Build, 
  AssignmentReturn, 
  Delete, 
  SwapHoriz 
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  nav1: {
    backgroundColor: "#fff",
    display: "flex",
    marginBottom: "0.5rem",
    padding: "0.5rem",
    height: "2.5rem",
    alignItems: "center",
    justifyContent: "space-between",
    userSelect: "none",
  },
  profileImg: {
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
    overflow: "hidden",
    marginLeft: "1rem",
  },
  nav2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    padding: "10px 15px",
  },
  navTitle: {
    fontSize: "1.1rem",
    fontWeight: "500",
    cursor: "pointer",
    padding: "2px 5px",
    marginLeft: "10px",
  },
  navMenuItem: {
    fontSize: "0.9rem",
  },
  container: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    alignItem: "start",
  },
  containerLeft: {
    display: "flex",
    flexDirection: "column",
    flex: 0.3,
    padding: "1rem",
    borderRadius: "10px",
    backgroundColor: "#fafafa",
    marginRight: "1rem",
  },
  containerRight: {
    display: "flex",
    flex: 0.7,
    padding: "5px",
  },
  navItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  navRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 0.5,
  },
  settings: {
    marginLeft: "auto",
  },
  settingsBtn: {
    display: "flex",
    gap: "0.5rem",
    flexWrap: "nowrap",
  },
  analyticsContainer: {
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: '0 1px 3px rgba(0,0,0,0.12)'
  },
  analyticsTitle: {
    fontWeight: 600,
    color: theme.palette.text.primary
  },
  analyticsItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  analyticsIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: '2rem',
  },
  analyticsContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  analyticsLabel: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
  analyticsValue: {
    fontSize: '1rem',
  },
}));

const InventoryHome = ({
  setShowConfigureWarehouseDialog,
  setShowConfigureStocksMoveDrawer,
  showConfigureStocksDrawer,
  showConfigureWarehouseDialog,
  setShowConfigureStocksDrawer,
  showConfigureStocksMoveDrawer,
  setShowWarehousesMenu,
  warehouse,
  setWarehouse,
  warehouses,
  setWarehouses,
  selectedProjects,
  setSelectedProjects,
  selectedOrganization,
  setSelectedOrganization,
  selectedWarehouses,
  setOrgMode,
  setSelectedProject,
  selectedWarehouse,
  warehousesToShow,
  orgMode,
  setSelectedWarehouse,
  setSelectedWarehouses,
  warehouseLoading,
  setWarehousesToShow,
  selectedProject,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [stockMoveLoading, setStockMoveLoading] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState();
  const [stockMoves, setStockMoves] = useState([]);
  const [stockSearchQuery, setStockSearchQuery] = useState("");
  const [projects, setProjects] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([
    "INBOUND",
    "ONSITE",
    "USED",
    "RETURN",
    "WASTED",
  ]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [shouldRefreshStocks, setShouldRefreshStocks] = useState(false);
  const [shouldRefreshStockMoves, setShouldRefreshStockMoves] = useState(false);

  const [organizationId, setOrganizationId] = useState();
  const [projectId, setProjectId] = useState();

  const [defaultFrom, setDefaultFrom] = useState();
  const [defaultTo, setDefaultTo] = useState();

  const [stockMoveMode, setStockMoveMode] = useState();

  const [stockMoveConfigTitle, setStockMoveConfigTitle] = useState("");

  const [stockMoveSettingsAnchorEl, setStockMoveSettingsAnchorEl] = useState();
  const [openStockMoveSettings, setOpenStockMoveSettings] = useState(false);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState([]);

  const [warehouseAnalytics, setWarehouseAnalytics] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);

  console.log(selectedStock,' is the selectedStock')

  const handleNewStockMove = () => {
    setStockMoveMode("CREATE_NEW");
    setDefaultFrom(null);
    setDefaultTo(null);
    setShowConfigureStocksMoveDrawer(true);
    setOpenStockMoveSettings(false);
  };

  const handleStockMoveChangeStatus = (from, to) => {
    setStockMoveMode("CHANGE_STATUS");
    if (from) {
      setDefaultFrom(from);
    } else {
      setDefaultFrom(null);
    }

    if (to) {
      setDefaultTo(to);
    } else {
      setDefaultTo(null);
    }

    setOpenStockMoveSettings(false);
    setShowConfigureStocksMoveDrawer(true);
  };

  const handleStockMoveTransfer = () => {
    setStockMoveMode("TRANSFER_WAREHOUSE");
    setDefaultFrom('ONSITE');
    setDefaultTo('ONSITE');
    setOpenStockMoveSettings(false);
    setShowConfigureStocksMoveDrawer(true);
  };

  // Get all stocks using selected warehouse. If no warehouse provided, get stocks of all warehouses of selected project and org
  const getAllStocks = async () => {
    try {
      setLoading(true);
      setStockMoveLoading(true)

      const { results } = await Api.post("/inventory/getAllStocks", {
        warehouse: selectedWarehouse?._id,
      });
      if (results?.length > 0) {
        console.log(results,' are the getAllStocks')
        setStocks(results);
        setSelectedStock(null);
        setShouldRefreshStockMoves(true);
      } else {
        setStocks([]);
        setSelectedStock(null);
        setStockMoves([]);
        setStockMoveLoading(false);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch stocks",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getStockMoves = async () => {
    try {
      setStockMoveLoading(true);
      const { results } = await Api.post("/inventory/getStockMoves", {
        types: selectedStatusOptions,
        stock: selectedStock?._id,
        stocks: stocks.map((item) => item._id) || null,
        fromDate,
        toDate,
      });
      if (results) {
        setStockMoves(results);
      } else {
        setStockMoves([]);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch stock moves",
        },
      });
      setStockMoveLoading(false);
    } finally {
      setStockMoveLoading(false);
    }
  };

  const searchStockByWarehouse = async () => {
    try {
      if (stockSearchQuery?.length < 3) {
        return;
      }
      setLoading(true);
      const { results } = await Api.post("/inventory/searchStockByWarehouse", {
        warehouseId: selectedWarehouse?._id,
        searchStr: stockSearchQuery,
        project: selectedProjects.map((item) => item._id),
        organization: selectedOrganization?._id,
      });
      setStocks(results);
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch stocks",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  // If /inventory route has ?project, get project and its associated org
  //  If /inventory route has ?organization, get org and its associated projects
  const handleProjectOrg = async () => {
    const _organizationId = urlParams.get("organizationId");
    const _projectId = urlParams.get("projectId");

    setOrganizationId(_organizationId);
    setProjectId(_projectId);


    if (_organizationId) {
      setOrgMode(true);
      const { data } = await Api.post("/projects/by-org", {
        organizationId: _organizationId,
      });
      if (data?.projects?.length > 0) {
        setProjects(data.projects);
        let selectedProjectAry = data.projects.map((item) => {
          return { ...item, isSelected: true };
        });
        setSelectedProjects(selectedProjectAry);
        setSelectedProject(data.projects[0]);

        setSelectedOrganization(data.organization);
      } else {
        // Warehouse belonging to only org!
        setSelectedOrganization(data.organization);
        setProjects([]);
      }
    } else if (_projectId) {
      const { data } = await Api.post("/project/org", {
        projectId: _projectId,
      });
      if (data?.project && data?.organization) {
        setSelectedProject(data.project);
        setSelectedOrganization(data.organization);

        let selectedProjectAry = [{ ...data.project, isSelected: true }];
        setSelectedProjects(selectedProjectAry);
      } else {
        history.goBack();
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "This project is not associated with any organization",
          },
        });
      }
    } else {
      history.goBack(); // Since no project and org is present
    }
  };

  const getWarehouseAnalytics = async () => {
    if (!selectedWarehouse) return;

    try {
      const { results } = await Api.post("/inventory/getWarehouseAnalytics", {
        warehouseIds: [selectedWarehouse._id],
      });
      console.log(results,' are the results')
      if (results && results.length > 0) {
        setWarehouseAnalytics(results[0]);
      }
    } catch (err) {
      console.error("Error fetching warehouse analytics:", err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch warehouse analytics",
        },
      });
    }
  };

  useEffect(() => {
    handleProjectOrg();
  }, [location]);

  useEffect(() => {
    if (shouldRefreshStocks) {
      getAllStocks();
      setShouldRefreshStocks(false);
    }
  }, [shouldRefreshStocks]);

  useEffect(() => {
    getAllStocks();
  }, [selectedWarehouse]);

  useDebounce(
    () => {
      searchStockByWarehouse();
    },
    500,
    [stockSearchQuery]
  );

  useEffect(() => {
    if (shouldRefreshStockMoves) {
      getStockMoves();
      setShouldRefreshStockMoves(false);
    }
  }, [shouldRefreshStockMoves]);

  useEffect(() => {
    if (warehouses.length>0) {
      getWarehouseAnalytics();
    }
  }, [selectedWarehouse, warehouses]);

  const AnalyticsHeader = ({ title, selectedStock, onClear }) => (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      mb: 1
    }}>
      <Typography variant="subtitle1" className={classes.analyticsTitle}>
        {title}
      </Typography>
      {selectedStock && (
        <Button 
          size="small"
          startIcon={<ArrowBack />}
          onClick={onClear}
        >
          Back to Warehouse Analytics
        </Button>
      )}
    </Box>
  );

  const AnalyticsItem = ({ icon: Icon, label, value = 0, quantity = 0 }) => (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      mb: 1
    }}>
      <Icon sx={{ 
        fontSize: '1.5rem',
        color: 'primary.main',
        bgcolor: 'primary.lighter',
        p: 0.5,
        borderRadius: 1
      }} />
      <Box>
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
        <Typography variant="body1" sx={{ mt: 0.25 }}>
          ₹{value.toLocaleString('en-IN', { maximumFractionDigits: 2 })}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Qty: {quantity.toLocaleString('en-IN')}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <StandardAppContainerRounded>
      <div className={classes.container}>
        <Box className={classes.containerLeft}>
          <InventoryStocks
            stocks={stocks}
            stockSearchQuery={stockSearchQuery}
            setStockSearchQuery={setStockSearchQuery}
            selectedStock={selectedStock}
            setSelectedStock={setSelectedStock}
            setShouldRefreshStockMoves={setShouldRefreshStockMoves}
            loading={loading}
          />
        </Box>
        <Box className={classes.containerRight}>
          <Box sx={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
                justifyContent: "end",
                margin: "0.5rem 0.5rem",
              }}
            >
              {warehouses?.length > 0 ? (
                <>
                  <FormControl size="small">
                    <Select
                      value={selectedWarehouse ? selectedWarehouse._id : ""} // Use a single value here, e.g., obj._id
                      onChange={(evt) =>
                        setSelectedWarehouse(
                          warehouses.find((obj) => obj._id === evt.target.value)
                        )
                      }
                    >
                      {warehouses?.length > 0 &&
                        warehouses.map((obj) => (
                          <MenuItem key={obj?._id} value={obj?._id}>
                            {obj?.name} 
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setWarehouse(null);
                      setShowWarehousesMenu(false);
                      setShowConfigureWarehouseDialog(true);
                    }}
                    color="primary"
                    style={{ marginLeft: "4px", marginRight: "10px" }}
                  >
                    <Add />
                  </IconButton>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={(event) => {
                      if (selectedWarehouse?._id) {
                        setStockMoveSettingsAnchorEl(event.currentTarget);
                        setOpenStockMoveSettings(true);
                      } else {
                        dispatch({
                          type: "AddApiAlert",
                          payload: {
                            success: false,
                            message: "Please select a warehouse first",
                          },
                        });
                      }
                    }}
                    endIcon={<Add />}
                  >
                    Actions
                  </Button>
                </>
              ) : (
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setWarehouse(null);
                    setShowWarehousesMenu(false);
                    setShowConfigureWarehouseDialog(true);
                  }}
                  endIcon={<Add />}
                >
                  Create Warehouse
                </Button>
              )}
            </div>
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                justifyContent: "space-between",
                padding: "5px 0px",
              }}
            >




              <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
                {selectedStock
                  ? selectedStock?.product?.name
                  : "Showing All Stocks"}
              </Typography>




              <Box display="flex" alignItems="center">
                {warehouses?.length > 0 && (
                  <Box display="flex" alignItems="center">
                    <Button
                      variant="outlined"
                      startIcon={<img src="/icons/Filter.svg" />}
                      onClick={() => setShowFilterDialog(true)}
                    >
                      Filters
                    </Button>
                  </Box>
                )}

                <Menu
                  anchorEl={stockMoveSettingsAnchorEl}
                  open={openStockMoveSettings}
                  onClose={() => setOpenStockMoveSettings(false)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >

                  <MenuItem
                    onClick={() => {
                      handleNewStockMove();
                      setStockMoveConfigTitle("Set Stock Base");
                    }}
                  >
                    Set Stock Base
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleStockMoveChangeStatus("ONSITE", "USED");
                      setStockMoveConfigTitle("Finished");
                    }}
                  >
                    Finished
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleStockMoveChangeStatus("ONSITE", "WASTED");
                      setStockMoveConfigTitle("Wasted");
                    }}
                  >
                    Wasted
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleStockMoveChangeStatus(null, "RETURN");
                      setStockMoveConfigTitle("Returned");
                    }}
                  >
                    Returned
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleStockMoveChangeStatus();
                      setStockMoveConfigTitle("Change Status");
                    }}
                  >
                    Change Status
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleStockMoveTransfer();
                      setStockMoveConfigTitle("Transfer To Warehouse");
                    }}
                  >
                    Transfer To Warehouse
                  </MenuItem>
                </Menu>

                <InventoryFilterDialog
                  open={showFilterDialog}
                  setOpen={setShowFilterDialog}
                  loading={loading}
                  projects={projects}
                  warehouses={warehouses}
                  selectedProjects={selectedProjects}
                  setSelectedProjects={setSelectedProjects}
                  selectedWarehouses={selectedWarehouses}
                  setSelectedWarehouses={setSelectedWarehouses}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  setShouldRefreshStocks={setShouldRefreshStocks}
                  fromDate={fromDate}
                  setFromDate={setFromDate}
                  toDate={toDate}
                  setToDate={setToDate}
                  warehousesToShow={warehousesToShow}
                  setWarehousesToShow={setWarehousesToShow}
                  showOrgData={orgMode}
                  selectedStatusOptions={selectedStatusOptions}
                  setSelectedStatusOptions={setSelectedStatusOptions}
                />
              </Box>
            </Box>

            {warehouses?.length > 0 && (
              <Box className={classes.analyticsContainer}>
                <AnalyticsHeader 
                  title={selectedStock ? `Stock Analytics: ${selectedStock?.product?.name}` : "Warehouse Analytics"} 
                  selectedStock={selectedStock}
                  onClear={() => setSelectedStock(null)}
                />
                
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card elevation={0} sx={{ bgcolor: 'background.default' }}>
                      <CardContent>
                        <AnalyticsItem 
                          icon={LocalShipping} 
                          label="Inbound" 
                          value={selectedStock ? selectedStock.inboundCost : warehouseAnalytics?.totalInboundCost} 
                          quantity={selectedStock ? selectedStock.inboundQuantity : warehouseAnalytics?.totalInboundQuantity}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card elevation={0} sx={{ bgcolor: 'background.default' }}>
                      <CardContent>
                        <AnalyticsItem 
                          icon={Store} 
                          label="Onsite" 
                          value={selectedStock ? selectedStock.onsiteCost : warehouseAnalytics?.totalOnsiteCost} 
                          quantity={selectedStock ? selectedStock.onsiteQuantity : warehouseAnalytics?.totalOnsiteQuantity}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card elevation={0} sx={{ bgcolor: 'background.default' }}>
                      <CardContent>
                        <AnalyticsItem 
                          icon={Build} 
                          label="Used" 
                          value={selectedStock ? selectedStock.usedCost : warehouseAnalytics?.totalUsedCost} 
                          quantity={selectedStock ? selectedStock.usedQuantity : warehouseAnalytics?.totalUsedQuantity}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card elevation={0} sx={{ bgcolor: 'background.default' }}>
                      <CardContent>
                        <AnalyticsItem 
                          icon={AssignmentReturn} 
                          label="Return" 
                          value={selectedStock ? selectedStock.returnCost : warehouseAnalytics?.totalReturnCost} 
                          quantity={selectedStock ? selectedStock.returnQuantity : warehouseAnalytics?.totalReturnQuantity}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card elevation={0} sx={{ bgcolor: 'background.default' }}>
                      <CardContent>
                        <AnalyticsItem 
                          icon={Delete} 
                          label="Wasted" 
                          value={selectedStock ? selectedStock.wastedCost : warehouseAnalytics?.totalWastedCost} 
                          quantity={selectedStock ? selectedStock.wastedQuantity : warehouseAnalytics?.totalWastedQuantity}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card elevation={0} sx={{ bgcolor: 'background.default' }}>
                      <CardContent>
                        <AnalyticsItem 
                          icon={SwapHoriz} 
                          label="Transferred" 
                          value={selectedStock ? selectedStock.transferredCost : warehouseAnalytics?.totalTransferredCost} 
                          quantity={selectedStock ? selectedStock.transferredQuantity : warehouseAnalytics?.totalTransferredQuantity}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            )}

            {selectedStock && (
              <Box className={classes.analyticsContainer}>
                <Typography variant="h6" className={classes.analyticsTitle}>
                  Stock Analytics
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <AnalyticsItem 
                      icon={LocalShipping} 
                      label="Inbound" 
                      value={selectedStock.inboundCost}
                      quantity={selectedStock.inboundQuantity}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <AnalyticsItem 
                      icon={Store} 
                      label="Onsite" 
                      value={selectedStock.onsiteCost}
                      quantity={selectedStock.onsiteQuantity}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <AnalyticsItem 
                      icon={Build} 
                      label="Used" 
                      value={selectedStock.usedCost}
                      quantity={selectedStock.usedQuantity}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <AnalyticsItem 
                      icon={AssignmentReturn} 
                      label="Return" 
                      value={selectedStock.returnCost}
                      quantity={selectedStock.returnQuantity}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <AnalyticsItem 
                      icon={Delete} 
                      label="Wasted" 
                      value={selectedStock.wastedCost}
                      quantity={selectedStock.wastedQuantity}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <AnalyticsItem 
                      icon={SwapHoriz} 
                      label="Transferred" 
                      value={selectedStock.transferredCost}
                      quantity={selectedStock.transferredQuantity}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            <InventoryStockMoves
              stockMoves={stockMoves}
              setShouldRefreshStocks={setShouldRefreshStocks}
              setShouldRefreshStockMoves={setShouldRefreshStockMoves}
              loading={stockMoveLoading}
              selectedWarehouse={selectedWarehouse}
              warehouses={warehouses}
              selectedOrganization={selectedOrganization}
              mainEntity={selectedWarehouse?.project || selectedWarehouse?.organization}
              mainEntityProfileId={selectedWarehouse?.project?.profile || selectedWarehouse?.organization?.profile}
            />
          </Box>
        </Box>
      </div>
      {showConfigureWarehouseDialog && (
        <WarehouseConfigDialog
          open={showConfigureWarehouseDialog}
          setOpen={setShowConfigureWarehouseDialog}
          loading={warehouseLoading}
          defaultOrganizationId={organizationId}
          defaultProjectId={projectId}
          warehouse={warehouse}
          callback={(data) => {
            const updatedItems = [...warehouses];
            const index = updatedItems.findIndex(
              (item) => item._id === data._id
            );
            if (index !== -1) {
              updatedItems[index] = data;
              setWarehouses(updatedItems);
              setWarehouse(data);
              setSelectedWarehouse(data);
            } else {
              setWarehouses([...warehouses, data]);
              setSelectedWarehouse(data);
            }
          }}
        />
      )}

      <StocksConfigDrawer
        open={showConfigureStocksDrawer}
        setOpen={setShowConfigureStocksDrawer}
        selectedWarehouse={selectedWarehouse}
        setShouldRefreshStocks={setShouldRefreshStocks}
        selectedOrganization={selectedOrganization}
        selectedProject={selectedProject}
        mainEntity={
          selectedWarehouse?.project || selectedWarehouse?.organization
        }
        mainEntityProfileId={
          selectedWarehouse?.project?.profile ||
          selectedWarehouse?.organization?.profile
        }
      />
      <StocksMoveConfigDrawer
        open={showConfigureStocksMoveDrawer}
        setOpen={setShowConfigureStocksMoveDrawer}
        selectedWarehouse={selectedWarehouse}
        warehouses={warehouses}
        stockMoveMode={stockMoveMode}
        setShouldRefreshStocks={setShouldRefreshStocks}
        defaultFrom={defaultFrom}
        defaultTo={defaultTo}
        title={stockMoveConfigTitle}
        selectedOrganization={selectedOrganization}
        mainEntity={
          selectedWarehouse?.project || selectedWarehouse?.organization
        }
        mainEntityProfileId={
          selectedWarehouse?.project?.profile ||
          selectedWarehouse?.organization?.profile
        }
      />
    </StandardAppContainerRounded>
  );
};

export default InventoryHome;
