import React, { useEffect, useState } from "react";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import CreateOffering from "../../finance2o/productsAndServices/create.offering.only";
import { useDebounce } from "react-use";
import { Delete } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import Autocomplete from "../../styled/generic/Autocomplete";
import TextField from "../../styled/generic/TextField";
import Select from "../../styled/generic/Select";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import StockTableContainer from "../../styled/generic/StockTableContainer";
import OfferingCreateDrawer from "../../OfferingsAndProducts/Offering.Create.Drawer";

const StocksMoveConfigDrawer = ({
  open,
  setOpen,
  selectedWarehouse,
  warehouses,
  stockMoveMode,
  setShouldRefreshStocks,
  defaultFrom,
  defaultTo,
  title,
  selectedOrganization,
  mainEntity,
  mainEntityProfileId,
  initialStocks
}) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [productText, setProductText] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [offeringCreateOpen, setOfferingCreateOpen] = useState(false);
  const [offeringCreateLoading, setOfferingCreateLoading] = useState(false);
  const [generateGRN, setGenerateGRN] = useState(false);

  const [tx, setTx] = useState({ type: "Bill" });
  const statusOptions = ["INBOUND", "ONSITE", "USED", "RETURN", "WASTED"];
  const [fromStatusOptions, setFromStatusOptions] = useState([
    "INBOUND",
    "ONSITE",
    "USED",
    "RETURN",
    "WASTED",
  ]);
  const [toStatusOptions, setToStatusOptions] = useState([
    "INBOUND",
    "ONSITE",
    "USED",
    "RETURN",
    "WASTED",
  ]);

  const [stocks, setStocks] = useState([]); // They are stock moves

  useEffect(() => {
    if (open) {
      setProductText("");
      setGenerateGRN(false);
      if (initialStocks && initialStocks.length > 0) {
        setStocks(initialStocks);
      } else {
        setStocks([
          {
            product: null,
            quantity: 0,
            rate: 0,
            fromType: defaultFrom,
            toType: defaultTo,
            fromWarehouse:selectedWarehouse,
            toWarehouse: selectedWarehouse
          },
        ]);
      }
    }
  }, [open, defaultFrom, defaultTo, initialStocks]);

  const searchProducts = async () => {
    try {
      setLoadingProducts(true);
      console.log('searchProducts called')
      const { data } = await Api.post("/wallet/offering/search-item", {
        profileId: mainEntityProfileId,
        searchStr: productText,
      });
      if (data) {
        setProducts(data);
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoadingProducts(false);
    }
  };

  const handleProductSearch = async () => {
    if (productText && productText?.length >= 3) {
      await searchProducts();
    }
  };

  useDebounce(
    () => {
      handleProductSearch();
    },
    500,
    [productText]
  );

  const addNewStockMove = async () => {
    setStocks([
      ...stocks,
      {
        product: null,
        quantity: 0,
        rate: 0,
        fromType: "INBOUND",
        toType: "INBOUND",
        fromWarehouse:
          stockMoveMode === "CREATE_NEW" || stockMoveMode === "CHANGE_STATUS"
            ? null
            : selectedWarehouse,
        toWarehouse:
          stockMoveMode === "CREATE_NEW" || stockMoveMode === "CHANGE_STATUS"
            ? selectedWarehouse
            : null,
      },
    ]);
  };

  const createStockMoves = async () => {
    if (stocks?.length <= 0) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Please add atleast one stock move",
        },
      });
      return;
    }

    // Add quantity validation
    const invalidQuantities = stocks.some(stock => !stock.quantity || stock.quantity <= 0);
    if (invalidQuantities) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Quantity should be greater than 0 for all items",
        },
      });
      return;
    }

    try {
      setLoading(true);
      const { results } = await Api.post("/inventory/handleStockMoves", {
        stockMoveList: stocks,
        mode: stockMoveMode,
        project: selectedWarehouse?.project,
        organization: selectedWarehouse?.organization,
        user: user?._id,
        generateGRN: generateGRN,
      });
      if (results) {
        const { success, data, message } = results;
        if (success) {
          setShouldRefreshStocks(true);
          setOpen(false);
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: true,
              message: message,
            },
          });
        } else {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: message,
            },
          });
        }
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to create stocks",
          },
        });
      }
    } catch (err) {
      console.log("Error: ", err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create stocks",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGRNSelection = (chk) => {
    if (chk) {
      setGenerateGRN(true);
      let updatedStocks = [...stocks];
      updatedStocks.forEach((item) => {
        item.toType = "ONSITE";
        if (item.fromType === "ONSITE") {
          item.fromType = null;
        }
      });
      setStocks(updatedStocks);
      setToStatusOptions(["ONSITE"]);
    } else {
      setGenerateGRN(false);
      setToStatusOptions(statusOptions);
    }
  };

  useEffect(() => {
    if (defaultFrom) {
      setFromStatusOptions([defaultFrom]);
    } else {
      if (stockMoveMode === "CHANGE_STATUS") {
        if (defaultTo === "RETURN") {
          // For returns, allow both INBOUND and ONSITE as from statuses
          setFromStatusOptions(["INBOUND", "ONSITE"]);
        } else {
          // For other changes, maintain original logic
          setFromStatusOptions(["INBOUND", "ONSITE"]);
        }
      } else {
        // For other modes, use all status options
        setFromStatusOptions(statusOptions);
      }
    }

    if (defaultTo) {
      setToStatusOptions([defaultTo]);
    } else {
      if (stockMoveMode === "CHANGE_STATUS") {
        setToStatusOptions([]); // Will be set based on fromType
      } else {
        setToStatusOptions(statusOptions);
      }
    }
  }, [defaultFrom, defaultTo, stockMoveMode]);

  useEffect(() => {
    if (stocks?.length > 0 && stockMoveMode === "CHANGE_STATUS") {
      const fromType = stocks[0].fromType;
      
      if (defaultTo === "RETURN") {
        // If it's a return operation, keep RETURN as the only option
        setToStatusOptions(["RETURN"]);
      } else if (fromType === "INBOUND") {
        setToStatusOptions(["ONSITE"]);
      } else if (fromType === "ONSITE") {
        setToStatusOptions(["USED", "WASTED", "RETURN", "TRANSFERRED"]);
      } else {
        setToStatusOptions([]);
      }

      // Update all stocks to have valid toType options
      let updatedStocks = [...stocks];
      updatedStocks.forEach(stock => {
        if (defaultTo === "RETURN") {
          stock.toType = "RETURN";
        } else if (fromType === "INBOUND" && stock.toType !== "ONSITE") {
          stock.toType = "ONSITE";
        } else if (fromType === "ONSITE" && !["USED", "WASTED", "RETURN", "TRANSFERRED"].includes(stock.toType)) {
          stock.toType = "USED";
        }
      });
      setStocks(updatedStocks);
    }
  }, [stocks?.length > 0 && stocks[0].fromType, stockMoveMode, defaultTo]);

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    switch (stockMoveMode) {
      case "CREATE_NEW":
        setColumns([
          "Product",
          "Rate",
          "Quantity",
          "To Warehouse",
          "To Status",
          "Actions",
        ]);
        break;

      case "CHANGE_STATUS":
        setColumns([
          "Product",
          "Rate",
          "Quantity",
          "To Warehouse",
          "From Status",
          "To Status",
        ]);
        break;

      case "TRANSFER_WAREHOUSE":
        setColumns([
          "Product",
          "Rate",
          "Quantity",
          "From Warehouse",
          "To Warehouse",
          "From Status",
          "To Status",
        ]);
        break;

      default:
        setColumns([]);
    }
  }, [stockMoveMode]);

  return (
    <DrawerContainer width="80vw" open={open} setOpen={setOpen} title={title}>
      <StockTableContainer columns={columns}>
        {stocks?.map((stock, index) => {
          return (
            <tr>
              <td>
                <Autocomplete
                  value={stock?.product}
                  onChange={(event, newValue) => {
                    let newStockAry = [...stocks];
                    newStockAry[index].product = newValue;
                    newStockAry[index].rate = newValue?.purchasePrice|| 0;
                    setStocks(newStockAry);
                  }}
                  options={products}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="text"
                      placeholder="Search"
                      value={stock?.product?.name || productText}
                      onChange={(e) => {
                        setProductText(e.target.value);
                      }}
                    />
                  )}
                  loading={loadingProducts}
                  getOptionLabel={(option) => option?.name}
                  componentsProps={{
                    paper: {
                      sx: {
                        border: "1px solid lightgray",
                        marginLeft: 1,
                        width: 484,
                      },
                    },
                  }}
                  noOptionsText={
                    <Button
                      onClick={() => {
                        setOfferingCreateOpen(true);
                      }}
                      size="small"
                      variant="text"
                      autoCapitalize="false"
                    >
                      Create New?
                    </Button>
                  }
                />
                <OfferingCreateDrawer
                  purchase={true}
                  selling={false}
                  wallet={mainEntity?.wallet}
                  library={mainEntity?.wallet?.defaultLibrary}
                  allCategories={[]}
                  profileId={mainEntityProfileId}
                  createOfferingDawer={offeringCreateOpen}
                  setCreateOfferingDrawer={setOfferingCreateOpen}
                  incomeChartAccounts={[]}
                  setIncomeChartAccounts={() => {}}
                  expenseChartAccounts={[]}
                  setExpenseChartAccounts={() => {}}
                  afterOfferingCreateOrEdit={(offeringRelation, isEdit) => {
                    setSelectedProduct(offeringRelation);
                  }}
                />
              </td>
              <td>
                <TextField
                  type="number"
                  placeholder="Rate"
                  value={stock?.rate}
                  fullWidth
                  onChange={(e) => {
                    let newStockAry = [...stocks];
                    newStockAry[index].rate = e.target.value;
                    setStocks(newStockAry);
                  }}
                />
              </td>
              <td>
                <TextField
                  type="number"
                  placeholder="Quantity"
                  value={stock?.quantity}
                  fullWidth
                  onChange={(e) => {
                    let newStockAry = [...stocks];
                    newStockAry[index].quantity = e.target.value;
                    setStocks(newStockAry);
                  }}
                />
              </td>
              {stockMoveMode === "TRANSFER_WAREHOUSE" && (
                <td>
                  <Select
                    fullWidth
                    value={stock?.fromWarehouse ? stock?.fromWarehouse._id : ""}
                    onChange={(evt) => {
                      let val = warehouses.find(
                        (obj) => obj._id === evt.target.value
                      );
                      let newStockAry = [...stocks];
                      newStockAry[index].fromWarehouse = val;
                      setStocks(newStockAry);
                    }}
                  >
                    {warehouses?.length > 0 &&
                      warehouses.map((obj) => (
                        <MenuItem key={obj._id} value={obj._id}>
                          {obj?.name}{" "}
                          {obj?.belongsToOrg
                            ? "(ORG)"
                            : obj?.project?.displayName}
                        </MenuItem>
                      ))}
                  </Select>
                </td>
              )}
              <td>
                <Select
                  fullWidth
                  value={stock?.toWarehouse ? stock?.toWarehouse._id : ""}
                  onChange={(evt) => {
                    let val = warehouses.find(
                      (obj) => obj._id === evt.target.value
                    );
                    let newStockAry = [...stocks];
                    newStockAry[index].toWarehouse = val;
                    setStocks(newStockAry);
                  }}
                >
                  {warehouses?.length > 0 &&
                    warehouses.map((obj) => (
                      <MenuItem key={obj._id} value={obj._id}>
                        {obj?.name}{" "}
                        {obj?.belongsToOrg
                          ? "(ORG)"
                          : obj?.project?.displayName}
                      </MenuItem>
                    ))}
                </Select>
              </td>
              {(stockMoveMode === "CHANGE_STATUS" ||
                stockMoveMode === "TRANSFER_WAREHOUSE") && (
                <td>
                  <Select
                    fullWidth
                    value={stock?.fromType}
                    onChange={(evt) => {
                      let newStockAry = [...stocks];
                      newStockAry[index].fromType = evt.target.value;
                      setStocks(newStockAry);
                    }}
                  >
                    {fromStatusOptions.map((item, i) => (
                      <MenuItem key={i} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              )}
              <td>
                <Select
                  fullWidth
                  value={stock?.toType}
                  onChange={(evt) => {
                    let newStockAry = [...stocks];
                    newStockAry[index].toType = evt.target.value;
                    setStocks(newStockAry);
                  }}
                >
                  {toStatusOptions.map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </td>
              <td>
                <IconButton
                  aria-placeholder="delete"
                  size="small"
                  onClick={() => {
                    let newStockAry = [...stocks];
                    newStockAry.splice(index, 1);
                    setStocks(newStockAry);
                  }}
                >
                  <Delete />
                </IconButton>
              </td>
            </tr>
          );
        })}
      </StockTableContainer>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        style={{ marginTop: "10px" }}
      >
        <Button
          variant="outlined"
          onClick={() => addNewStockMove()}
          style={{ marginRight: "5px" }}
        >
          Add New
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={() => createStockMoves()}
          style={{ marginLeft: "5px" }}
          loading={loading}
        >
          Create
        </LoadingButton>
      </Box>


    </DrawerContainer>
  );
};

export default StocksMoveConfigDrawer;