import React, { useEffect, useState } from "react";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import TextField from "../../styled/generic/TextField";
import FormBox from "../../styled/generic/FormBox";
import OrgPickerDropdown from "../../styled/generic/OrgPickerDropdown";
import Dialog from "../../styled/generic/Dialog";
import ProjectPickerDropdown from "../../styled/generic/ProjectPickerDropdown";
import ProjectBlockPickerDropdown from "../../styled/generic/ProjectBlockPickerDropdown";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";

const WarehouseConfigDialog = ({
  open,
  setOpen,
  defaultProjectId,
  defaultOrganizationId,
  warehouse,
  callback,
}) => {
  const { user } = useSelector((state) => state.auth);

  const [orgTeams, setOrgTeams] = useState([]);
  const [projectTeams, setProjectTeams] = useState([]);
  const [projectBlocks, setProjectBlocks] = useState([]);

  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedProjectBlock, setSelectedProjectBlock] = useState("");

  const [warehouseName, setWarehouseName] = useState("");

  const [loading, setLoading] = useState(false);
  const [makeDefault, setMakeDefault] = useState(false);

  const [belongsToOrg, setBelongsToOrg] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    if (open) {
      setWarehouseName("");
      setSelectedOrg("");
      setSelectedProject("");
      setSelectedProjectBlock("");
    }
  }, [open]);

  const getBasicData = async () => {
    const res = await Api.post("shared/getBasicData", {
      userProfileId: user?.profile,
    });

    if (res?.data) {
      const data = res?.data;
      const orgTeamRes = data.orgTeams;
      const projectTeamRes = data.projectTeams;
      setOrgTeams(orgTeamRes);
      setProjectTeams(projectTeamRes);
    }
  };

  useEffect(() => {
    setWarehouseName(warehouse?.name || "");

    if (warehouse && warehouse?._id) {
      setSelectedOrg(
        orgTeams.find(
          (item) => item?.parent?._id === warehouse?.organization?._id
        )?.parent
      );
      setSelectedProject(
        projectTeams.find(
          (item) => item?.parent?._id === warehouse?.project?._id
        )?.parent
      );
      setSelectedProjectBlock(
        projectBlocks.find((item) => item?._id === warehouse?.projectBlock)
      );
    } else {
      if (defaultOrganizationId) {
        setSelectedOrg(
          orgTeams.find((item) => item?.parent?._id === defaultOrganizationId)
            ?.parent
        );
      }
      // if (defaultProjectId) {
      //   setSelectedProject(
      //     projectTeams.find((item) => item?.parent?._id === defaultProjectId)
      //       ?.parent
      //   );
      // }
    }
  }, [warehouse, orgTeams, projectTeams]);

  useEffect(() => {

    if (warehouse) {
      if (warehouse.belongsToOrg) {
        if (selectedOrg?.defaultWarehouse === warehouse._id) {
          setMakeDefault(true);
        } else {
          setMakeDefault(false);
        }
      } else {
        if (selectedProject?.defaultWarehouse === warehouse._id) {
          setMakeDefault(true);
        } else {
          setMakeDefault(false);
        }
      }
    }
  }, [warehouse, selectedProject, selectedOrg]);

  const getProjectBlocks = async () => {
    if (!selectedProject || !selectedProject?._id) {
      return;
    }

    try {
      const { data } = await Api.post("/public/project/block/get", {
        projectId: selectedProject?._id,
      });

      if (data) {
        setProjectBlocks(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (open) {
      getBasicData();
    }
  }, [open]);

  useEffect(() => {
    getProjectBlocks();
  }, [selectedProject]);

  const createWarehouse = async () => {
    if (!warehouseName || !selectedOrg?._id) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Please provide warehouse name and organization",
        },
      });
      return;
    }

    try {
      setLoading(true);

      let _projectId = selectedProject?._id;
      if(belongsToOrg) {
        _projectId = null;
      }

      const { results } = await Api.post("/inventory/createWarehouse", {
        name: warehouseName,
        location: selectedProject?.location,
        createdBy: user?.profile,
        projectBlock: selectedProjectBlock,
        projectId: _projectId,
        organizationId: selectedOrg?._id,
        userId: user?._id,
        makeDefault: makeDefault,
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Warehouse created successfully",
        },
      });

      setOpen(false);
      callback(results);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create warehouse",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const updateWarehouse = async () => {
    try {
      setLoading(true);
      const { results } = await Api.post("/inventory/updateWarehouse", {
        _id: warehouse?._id,
        updateObj: {
          name: warehouseName,
        },
        makeDefault: makeDefault,
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Warehouse updated successfully",
        },
      });

      setOpen(false);
      callback(results);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to update warehouse",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} setOpen={setOpen} title="Configure Warehouse">
      <Box>
        <Box style={{ padding: "5px 0px", width: "65vw" }}>
          <FormBox label="Warehouse Name">
            <TextField
              fullWidth
              variant="outlined"
              value={warehouseName}
              onChange={(e) => setWarehouseName(e.target.value)}
              placeholder="Enter Title"
            />
          </FormBox>

          {!(warehouse && warehouse?._id) && (
            <>
              <FormBox label="Organization">
                <OrgPickerDropdown
                  selectedOrg={selectedOrg}
                  setSelectedOrg={setSelectedOrg}
                  defaultOrganizationId={defaultOrganizationId}
                  hideLabel={true}
                  fullWidth={true}
                />
              </FormBox>

              <FormBox label="Project">
                <ProjectPickerDropdown
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  defaultProjectId={defaultProjectId}
                  hideLabel={true}
                  fullWidth={true}
                  disabled={belongsToOrg}
                />
              </FormBox>

              <FormBox label="Project Block">
                <ProjectBlockPickerDropdown
                  selectedProjectBlock={selectedProjectBlock}
                  setSelectedProjectBlock={setSelectedProjectBlock}
                  projectBlocks={projectBlocks}
                  projectId={selectedProject?._id}
                  hideLabel={true}
                  fullWidth={true}
                />
              </FormBox>
            </>
          )}

          <Box style={{ marginTop: "10px" }} display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={belongsToOrg}
                  onChange={(e, checked) => setBelongsToOrg(checked)}
                />
              }
              label="Belongs to organization only"
              style={{ marginRight: "10px" }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={makeDefault}
                  onChange={(e, checked) => setMakeDefault(checked)}
                />
              }
              label="Make default warehouse"
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <DuoButtonGroup
            primaryButtonText={warehouse && warehouse._id ? "Update" : "Create"}
            primaryButtonListener={() => {
              if (warehouse && warehouse._id) {
                updateWarehouse();
              } else {
                createWarehouse();
              }
            }}
            hideSecondary={true}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default WarehouseConfigDialog;
