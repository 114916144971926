import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  tableSty: {
    width: "100%",
    border: "1px solid rgba(0,0,0,0.10)",
    borderCollapse: "collapse",
    backgroundColor: "#F5F7FA",
    marginTop: "10px",
    "& tr": {
      height: "50px",
      marginTop: "5px",
      cursor: "pointer",
      border: "1px solid rgba(0,0,0,0.10)",
      borderCollapse: "collapse",
      backgroundColor: "#FFFFFF",
    },
    "& th": {
      border: "1px solid rgba(0,0,0,0.10)",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "10px",
      backgroundColor: "#F5F7FA",
      textAlign: "left",
      fontSize: "0.85rem",
    },
    "& td": {
      border: "1px solid rgba(0,0,0,0.10)",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "15px",
      textAlign: "left",
      verticalAlign: "center",
      fontSize: "0.9rem",
      color: "#111111",
      backgroundColor: "#FFFFFF",
    },
  },
}));

const StockTableContainer = ({ columns, children }) => {
  const classes = useStyles();

  return (
    <table className={classes.tableSty}>
      <tr
        style={{
          borderBottom: "none",
          backgroundColor: "#F5F7FA",
        }}
      >
        {columns.map((col) => (
          <th>{col}</th>
        ))}
      </tr>
      {children}
    </table>
  );
};

export default StockTableContainer;
