import React, { useEffect, useState } from "react";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import CreateOffering from "../../finance2o/productsAndServices/create.offering.only";
import { useDebounce } from "react-use";
import TextField from "../../styled/generic/TextField";
import Select from "../../styled/generic/Select";
import Autocomplete from "../../styled/generic/Autocomplete";
import FormBox from "../../styled/generic/FormBox";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import StockTableContainer from "../../styled/generic/StockTableContainer";
import OfferingCreateDrawer from "../../OfferingsAndProducts/Offering.Create.Drawer";
import LibraryPickerDropdown from "../../styled/generic/LibraryPickerDropdown";

const StocksConfigDrawer = ({
  open,
  setOpen,
  selectedWarehouse,
  setShouldRefreshStocks,
  selectedOrganization,
  selectedProject,
  mainEntity, //project or org
  mainEntityProfileId, // warehouse.project.profile or warehouse.org.profile
}) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [productText, setProductText] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [offeringCreateOpen, setOfferingCreateOpen] = useState(false);
  const [offeringCreateLoading, setOfferingCreateLoading] = useState(false);
  const [tx, setTx] = useState({ type: "Bill" });
  const [stocks, setStocks] = useState([]);
  const [selectedLibrary, setSelectedLibrary] = useState();
  const [defaultLibraryId, setDefaultLibraryId] = useState();

  useEffect(() => {
    if (open) {
      setStocks([
        {
          valuationMethod: "FIFO",
        },
      ]);
    }
  }, [open]);

  const searchProducts = async () => {
    try {
      setLoadingProducts(true);
      const { data } = await Api.post("/wallet/offering/search-item", {
        profileId: mainEntityProfileId,
        searchStr: productText,
      });
      if (data) {
        setProducts(data);
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoadingProducts(false);
    }
  };

  const handleProductSearch = async () => {
    if (productText && productText.length >= 3) {
      await searchProducts();
    }
  };

  useDebounce(
    () => {
      handleProductSearch();
    },
    500,
    [productText]
  );

  const addNewStock = async () => {
    setStocks([
      ...stocks,
      {
        valuationMethod: "FIFO",
      },
    ]);
  };

  const createStocks = async () => {
    if (stocks?.length <= 0) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Please add atleast one stock",
        },
      });
      return;
    }
    try {
      let stockList = [];
      let shouldContinue = true;

      stocks.forEach((item, index) => {
        if (
          item?.product?._id &&
          selectedWarehouse?._id &&
          item?.valuationMethod
        ) {
          stockList.push({
            product: item?.product?._id,
            warehouse: selectedWarehouse?._id,
            valuationMethod: item?.valuationMethod,
          });
        } else {
          shouldContinue = false;
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: `Please fill all fields for stock at position ${
                index + 1
              }`,
            },
          });
          return;
        }
      });

      if (!shouldContinue) {
        return; // Exit function
      }

      const { data } = await Api.post("/inventory/createStocksInBulk", {
        stockList: stockList,
        project: selectedWarehouse?.project,
        organization: selectedWarehouse?.organization,
        user: user._id,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Stocks created successfully",
          },
        });
        setOpen(false);
        setShouldRefreshStocks(true);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to create stocks",
          },
        });
      }
    } catch (err) {
      console.log("Error: ", err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create stocks",
        },
      });
    }
  };

  // Reset when close
  useEffect(() => {
    if (!open) {
      setStocks([
        {
          valuationMethod: "FIFO",
        },
      ]);
    }
  }, [open]);

  return (
    <DrawerContainer open={open} setOpen={setOpen} title="Set Stocks">
      <Box>
        <StockTableContainer columns={["Product", "Valuation Method"]}>
          {stocks?.map((stock, index) => {
            return (
              <tr>
                <td>
                  <Autocomplete
                    value={stock?.product}
                    onChange={(event, newValue) => {
                      let newStockAry = [...stocks];
                      newStockAry[index].product = newValue;
                      setStocks(newStockAry);
                    }}
                    options={products}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        type="text"
                        placeholder="Enter product title"
                        value={productText}
                        onChange={(e) => {
                          setProductText(e.target.value);
                        }}
                      />
                    )}
                    loading={loadingProducts}
                    getOptionLabel={(option) => option?.name}
                    componentsProps={{
                      paper: {
                        sx: {
                          border: "1px solid lightgray",
                          marginLeft: 1,
                          width: 484,
                        },
                      },
                    }}
                    noOptionsText={
                      <Button
                        onClick={() => {
                          setOfferingCreateOpen(true);
                        }}
                        size="small"
                        variant="text"
                        autoCapitalize="false"
                      >
                        Create New?
                      </Button>
                    }
                  />
                </td>
                <td>
                  <Select
                    value={stock?.valuationMethod}
                    onChange={(evt) => {
                      let newStockAry = [...stocks];
                      newStockAry[index].valuationMethod = evt.target.value;
                      setStocks(newStockAry);
                    }}
                    fullWidth
                    disableUnderline
                    variant="standard"
                  >
                    <MenuItem value="FIFO">FIFO</MenuItem>
                    <MenuItem value="LIFO">LIFO</MenuItem>
                    <MenuItem value="AVERAGE">AVERAGE</MenuItem>
                  </Select>
                </td>
              </tr>
            );
          })}
        </StockTableContainer>

        <OfferingCreateDrawer
          purchase={true}
          selling={false}
          wallet={mainEntity?.wallet}
          library={mainEntity?.wallet?.defaultLibrary}
          allCategories={[]}
          profileId={mainEntityProfileId}
          createOfferingDawer={offeringCreateOpen}
          setCreateOfferingDrawer={setOfferingCreateOpen}
          incomeChartAccounts={[]}
          setIncomeChartAccounts={() => {}}
          expenseChartAccounts={[]}
          setExpenseChartAccounts={() => {}}
          afterOfferingCreateOrEdit={(offeringRelation, isEdit) => {
            setSelectedProduct(offeringRelation);
          }}
        />

        <DuoButtonGroup
          primaryButtonText="Create"
          primaryButtonListener={() => createStocks()}
          secondaryButtonText="Add New"
          secondaryButtonListener={() => addNewStock()}
        />
      </Box>
    </DrawerContainer>
  );
};

export default StocksConfigDrawer;
