import { IconButton, makeStyles } from "@material-ui/core";
import {
  AppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Api from "../../../helpers/Api";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import WarehouseConfigDialog from "./WarehouseConfigDialog";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import StocksConfigDrawer from "./StocksConfigDrawer";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import moment from "moment";
import LessText from "../../styled/CommonComponents/LessText";
import {
  ArrowDropDown,
  ExpandMoreSharp,
  MoreHoriz,
  Search,
  Settings,
  ArrowBackIos,
  Add,
  LayersOutlined,
} from "@material-ui/icons";
import { useDebounce } from "react-use";


import WarehouseView from "./WarehouseView";
import Procurement from "../procurements/Procurement";

import StandardContainer from "../../styled/generic/StandardContainer";

import InventoryHome from "./InventoryHome";


const useStyles = makeStyles((theme) => ({
  nav1: {
    backgroundColor: "#fff",
    display: "flex",
    marginBottom: "0.5rem",
    padding: "0.5rem",
    height: "2.5rem",
    alignItems: "center",
    justifyContent: "space-between",
    userSelect: "none",
  },
  profileImg: {
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
    overflow: "hidden",
    marginLeft: "1rem",
  },
  nav2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    padding: "10px 15px",
  },
  navTitle: {
    fontSize: "1.1rem",
    fontWeight: "500",
    cursor: "pointer",
    padding: "2px 5px",
    marginLeft: "10px",
  },
  navMenuItem: {
    fontSize: "0.9rem",
  },
  container: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    alignItem: "start",
  },
  containerLeft: {
    display: "flex",
    flexDirection: "column",
    flex: 0.3,
    padding: "1rem",
    borderRadius: "10px",
    backgroundColor: "#fafafa",
    marginRight: "1rem",
  },
  containerRight: {
    display: "flex",
    flex: 0.7,
    padding: "5px",
  },
  navItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  navRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 0.5,
  },
  settings: {
    marginLeft: "auto",
  },
  settingsBtn: {
    display: "flex",
    gap: "0.5rem",
    flexWrap: "nowrap",
  },
}));

const Inventory = () => {
  const classes = useStyles();

  const location = useLocation();

  const dispatch = useDispatch();

  const [tab, setTab] = useState("Inventory");

  const [warehouse, setWarehouse] = useState(null); // For warehouse config

  const [opsType, setOpsType] = useState("Material Request");

  const [showConfigureStocksDrawer, setShowConfigureStocksDrawer] =
    useState(false);
  const [showConfigureStocksMoveDrawer, setShowConfigureStocksMoveDrawer] =
    useState(false);
  const [showConfigureWarehouseDialog, setShowConfigureWarehouseDialog] =
    useState(false);

  const [showWarehousesMenu, setShowWarehousesMenu] = useState(false);

  const [warehouses, setWarehouses] = useState([]);

  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);

  const [selectedOrganization, setSelectedOrganization] = useState();

  const [warehouseLoading, setWarehouseLoading] = useState(true);
  const [orgMode, setOrgMode] = useState(false);
  const [warehousesToShow, setWarehousesToShow] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState();

  const [filteredOpsOrderType, setFilteredOpsOrderType] = useState("All");

  // Get warehouses based on selected project. If orgMode enabled, get org warehouses also
  const getWarehouses = async () => {
    if (!selectedOrganization?._id) {
      return;
    }

    try {
      setWarehouseLoading(true);

      const { results } = await Api.post(
        "/inventory/getWareHousesByOrgProject",
        {
          projectId: selectedProject ? selectedProject._id : null,
          organizationId: selectedOrganization?._id,
          mode: orgMode ? "organization" : "project",
        }
      );
      if (results && results.length > 0) {
        console.log(results, ' are the results')
        setWarehouses(results);
        setWarehousesToShow(results);
        setSelectedWarehouses(results);

        let wc = {};
        results.forEach((item) => {
          let key = item?.project ? item?.project?._id : "null-key";

          if (wc[key]) {
            wc[key].push(item);
          } else {
            wc[key] = [item];
          }
        });

        // New logic for setting selectedWarehouse
        if (orgMode) {
          // In organization mode
          const orgWarehouse = results.find(w => !w.project);
          setSelectedWarehouse(orgWarehouse || results[0]);
        } else {
          // In project mode
          const projectWarehouse = results.find(w => w.project?._id === selectedProject?._id);
          setSelectedWarehouse(projectWarehouse || results[0]);
        }
      }
    } catch (err) {
      console.log("Warehouse error: ", err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch warehouse",
        },
      });
    } finally {
      setWarehouseLoading(false);
    }
  };

  useEffect(() => {
    getWarehouses();
  }, [selectedProject, selectedOrganization]);

  return (
    <StandardContainer showAppBar={true} appBarTitle="Inventory">
      <div className={classes.nav2}>
        <div className={classes.navItems}>
          <span
            className={classes.navTitle}
            style={{
              borderBottom: tab === "Inventory" ? "3px solid #2D76E0" : "none",
              color: tab === "Inventory" ? "#000" : "#9B9DA3",
            }}
            onClick={(e) => {
              setTab("Inventory");
            }}
          >
            Inventory
          </span>
          <span
            className={classes.navTitle}
            onClick={(e) => {
              setTab("Warehouse");
            }}
            style={{
              borderBottom: tab === "Warehouse" ? "3px solid #2D76E0" : "none",
              color: tab === "Warehouse" ? "#000" : "#9B9DA3",
            }}
          >
            Warehouse
          </span>

          {/* <span
            className={classes.navTitle}
            style={{
              borderBottom:
                tab === "Material Request" ? "3px solid #2D76E0" : "none",
              color: tab === "Material Request" ? "#000" : "#9B9DA3",
            }}
            onClick={() => {
              setTab("Material Request");
              setFilteredOpsOrderType("Material Request");
            }}
          >
            Material Request
          </span>

          <span
            className={classes.navTitle}
            style={{
              borderBottom: tab === "GRN" ? "3px solid #2D76E0" : "none",
              color: tab === "GRN" ? "#000" : "#9B9DA3",
            }}
            onClick={() => {
              setTab("GRN");
              setFilteredOpsOrderType("GRN");
            }}
          >
            GRN
          </span> */}
        </div>

        <div className={classes.settings}>
          {/* <Button
            className={classes.settingsBtn}
            startIcon={<Settings style={{ fontSize: "1.3rem" }} />}
            variant="outlined"
            color="primary"
          >
            <Typography variant="button">Settings</Typography>
          </Button> */}
        </div>
      </div>

      {tab === "Inventory" && (
        <InventoryHome
          setShowConfigureWarehouseDialog={setShowConfigureWarehouseDialog}
          setShowConfigureStocksDrawer={setShowConfigureStocksDrawer}
          setShowConfigureStocksMoveDrawer={setShowConfigureStocksMoveDrawer}
          showConfigureWarehouseDialog={showConfigureWarehouseDialog}
          showConfigureStocksDrawer={showConfigureStocksDrawer}
          showConfigureStocksMoveDrawer={showConfigureStocksMoveDrawer}
          setShowWarehousesMenu={setShowWarehousesMenu}
          warehouse={warehouse}
          setWarehouse={setWarehouse}
          warehouses={warehouses}
          setWarehouses={setWarehouses}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          selectedWarehouses={selectedWarehouses}
          setOrgMode={setOrgMode}
          setSelectedProject={setSelectedProject}
          selectedWarehouse={selectedWarehouse}
          warehousesToShow={warehousesToShow}
          orgMode={orgMode}
          setSelectedWarehouse={setSelectedWarehouse}
          setSelectedWarehouses={setSelectedWarehouses}
          warehouseLoading={warehouseLoading}
          setWarehousesToShow={setWarehousesToShow}
          selectedProject={selectedProject}
        />
      )}

      {tab === "Warehouse" && (
        <div className={classes.container}>
          <WarehouseView
            setWarehouse={setWarehouse}
            setShowWarehousesMenu={setShowWarehousesMenu}
            setShowConfigureWarehouseDialog={setShowConfigureWarehouseDialog}
            warehouses={warehouses}
          />
        </div>
      )}

      {(tab === "Material Request") && (
        <Procurement
          fromComponent={true}
          type="Material Request"
          opsProp="Material Request"
          filteredOpsOrderType={filteredOpsOrderType}
        />
      )}

      {(tab === "GRN") && (
        <Procurement
          fromComponent={true}
          type="GRN"
          opsProp="GRN"
          filteredOpsOrderType={filteredOpsOrderType}
        />
      )}

      <WarehouseConfigDialog
        open={showConfigureWarehouseDialog}
        setOpen={setShowConfigureWarehouseDialog}
        defaultOrganizationId={selectedOrganization?._id}
        defaultProjectId={selectedProject?._id}
        warehouse={warehouse}
        callback={(data) => {
          const updatedItems = [...warehouses];
          const index = updatedItems.findIndex(
            (item) => item._id === data._id
          );
          if (index !== -1) {
            updatedItems[index] = data;
            setWarehouses(updatedItems);
            setWarehouse(data);
            setSelectedWarehouse(data);
          } else {
            setWarehouses([...warehouses, data]);
            setSelectedWarehouse(data);
          }
        }}
      />
    </StandardContainer>
  );
};

export default Inventory;