import { getLibraryCategoriesBasicData } from "../../OfferingsAndProducts/Api.call";

import { FormControl, InputLabel, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import Api from "../../../helpers/Api";
import { useSelector } from "react-redux";
import Select from "./Select";

const LibraryPickerDropdown = ({
  selectedLibrary,
  setSelectedLibrary,
  defaultLibraryId,
  size,
  hideLabel,
  fullWidth = false,
  profileId,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [libs, setLibs] = useState([]);

  const getLibs = async () => {
    try {
      const data = await getLibraryCategoriesBasicData({
        curProfileId: profileId,
        profileIds: [profileId],
      });
      const libraries =
        data?.librariesAndCategories?.map((item) => item.library) || [];
      setLibs(libraries);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleDefaultSelection = () => {
    if (libs?.length > 0 && defaultLibraryId) {
      let index = libs.findIndex((item) => item?._id === defaultLibraryId);
      if (index !== -1) {
        setSelectedLibrary(libs[index]);
      } else {
        setSelectedLibrary(libs[0]);
      }
    }
  };

  useEffect(() => {
    handleDefaultSelection();
  }, [defaultLibraryId]);

  useEffect(() => {
    getLibs();
  }, []);

  return (
    <FormControl fullWidth={fullWidth} size={size}>
      {!hideLabel && (
        <InputLabel
          id="demo-simple-select-helper-label"
          shrink={selectedLibrary !== null}
        >
          {selectedLibrary ? "Library" : "Select Library"}
        </InputLabel>
      )}

      <Select
        value={selectedLibrary}
        onChange={(evt) => setSelectedLibrary(evt.target.value)}
        label={selectedLibrary ? "Library" : "Select Library"}
        notched={hideLabel ? false : selectedLibrary !== null}
        displayEmpty
      >
        <MenuItem>{selectedLibrary?.name || "Select"}</MenuItem>
        {libs.length > 0 &&
          libs.map((obj) => (
            <MenuItem key={obj?._id} value={obj}>
              {obj?.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default LibraryPickerDropdown;
