import React, { useState} from "react";
import { Box, Typography, Card, CardContent, Grid, Chip, Tooltip, IconButton, Menu, MenuItem  } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import StatusChip from "../../styled/generic/StatusChip";
import { LocalShipping, CalendarToday, Inventory, Update, Delete, MoreVert } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
    borderRadius: "8px",
    position: "relative",
  },
  headerRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  productName: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  infoSection: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: "1.2rem",
    color: theme.palette.text.secondary,
  },
  value: {
    fontWeight: 400,
  },
  statusActions: {
    display: "flex",
    alignItems: "center",
  },
  statusChip: {
    marginRight: theme.spacing(1), // Closer to the More button
  },
  moreButton: {
    padding: 0, // Ensure the button doesn't take up too much space
  },
  cardContent: {
    padding: 0, // Reduced padding
    '&:last-child': {
      paddingBottom: 0, // Consistent bottom padding
    },
  },
  cardContentRoot: {
    padding: 0, // Applying minimal padding
    '&:last-child': {
      paddingBottom: 0, // Ensuring bottom padding is minimal
    },
  },
}));

const StockMoveCard = ({ move, onDelete, onActionSelect }) => {
  const classes = useStyles();
  const isOnsite = move.toType === "ONSITE";
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionSelect = (action) => {
    onActionSelect(move, action);
    handleClose();
  };

  const renderQuantityInfo = () => {
    const quantities = [
      { label: "Consumed", value: move.consumedQuantity, icon: <Delete fontSize="small" /> },
    ];

    return quantities.map(({ label, value, icon }) => 
      value > 0 && (
        <Tooltip key={label} title={`${label}: ${value}`}>
          <Chip
            icon={icon}
            label={value}
            size="small"
            className={classes.quantityChip}
          />
        </Tooltip>
      )
    );
  };

  return (
    <Card className={`${classes.card} ${isOnsite ? classes.onsiteCard : ''}`}>
      <CardContent classes={{ root: classes.cardContentRoot }}>
        
        {/* Header Row: Name, Status, and More Button */}
        <Box className={classes.headerRow}>
          <Typography variant="h6" className={classes.productName}>
            {move.product.name}
          </Typography>

          <Box className={classes.statusActions}>
            <StatusChip
              label={move.toType}
              variant={
                ["INBOUND", "USED"].includes(move.toType)
                  ? "Success"
                  : ["ONSITE"].includes(move.toType)
                  ? "Info"
                  : ["OUTBOUND"].includes(move.toType)
                  ? "Warning"
                  : "Error"
              }
              className={classes.statusChip}
            />

            <IconButton className={classes.moreButton} size="small" onClick={handleMoreClick}>
              <MoreVert fontSize="small" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleActionSelect("USED")}>Used</MenuItem>
              <MenuItem onClick={() => handleActionSelect("TRANSFERRED")}>Transferred</MenuItem>
              <MenuItem onClick={() => handleActionSelect("WASTED")}>Wasted</MenuItem>
            </Menu>
          </Box>
        </Box>

        {/* Stock Info */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box className={classes.infoSection}>
              <Inventory className={classes.icon} />
              <Typography variant="body2" className={classes.value}>
                {isOnsite 
                  ? `${parseFloat(move.remainingQuantity).toFixed(2)} @ ₹${parseFloat(move.rate).toFixed(2)}`
                  : `${parseFloat(move.quantity).toFixed(2)} @ ₹${parseFloat(move.rate).toFixed(2)}`
                }
                <Typography variant="subtitle1" className={classes.value}>
                  Total: ₹{isOnsite 
                    ? `${parseFloat(move.rate * move.remainingQuantity).toFixed(2)}`
                    : `${parseFloat(move.rate * move.quantity).toFixed(2)}`
                  }
                </Typography>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.infoSection}>
              <LocalShipping className={classes.icon} />
              <Typography variant="body2" className={classes.value}>
                {move.toWarehouse?.name || "VENDOR"}
              </Typography>
            </Box>
            <Box className={classes.infoSection}>
              <CalendarToday className={classes.icon} />
              <Typography variant="body2" className={classes.value}>
                {move.moveDate ? moment(move.moveDate).format("DD MMM YYYY") : ""}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Quantity Info */}
        {isOnsite && (
          <Box mt={1}>
            <Chip
              icon={<Update fontSize="small" />}
              label={`Initial: ${parseFloat(move.quantity).toFixed(2)}`}
              size="small"
              className={classes.remainingChip}
            />
            {renderQuantityInfo()}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default StockMoveCard;
