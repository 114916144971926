import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import Api from "../../../helpers/Api";
import StockMoveCard from "./StockMoveCard";
import StocksMoveConfigDrawer from "./StocksMoveConfigDrawer";

const InventoryStockMoves = ({
  stockMoves,
  setShouldRefreshStocks,
  setShouldRefreshStockMoves,
  loading,
  selectedWarehouse,
  warehouses,
  selectedOrganization,
  mainEntity,
  mainEntityProfileId
}) => {
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedMove, setSelectedMove] = useState(null);
  const [selectedAction, setSelectedAction] = useState("");
  const [initialStocks, setInitialStocks] = useState([]);
  const [stockMoveMode, setStockMoveMode] = useState("");

  const deleteStockMove = async (id) => {
    try {
      const { results } = await Api.post("/inventory/deleteStockMove", {
        _id: id,
      });
      if (results) {
        setShouldRefreshStocks(true);
        setShouldRefreshStockMoves(true);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to delete stock move",
        },
      });
    }
  };

  const handleActionSelect = (move, action) => {
    setSelectedMove(move);
    setSelectedAction(action);
    
    let mode;
    let toType;

    switch (action) {
      case "USED":
        mode = "CHANGE_STATUS";
        toType = "USED";
        break;
      case "TRANSFERRED":
        mode = "TRANSFER_WAREHOUSE";
        toType = "ONSITE"; // Assuming transferred stock becomes ONSITE at new location
        break;
      case "WASTED":
        mode = "CHANGE_STATUS";
        toType = "WASTED";
        break;
      default:
        mode = "CREATE_NEW";
        toType = move.toType;
    }

    setStockMoveMode(mode);
    
    // Create the initial stock object based on the selected move
    const initialStock = {
      product: move.product,
      quantity: move.remainingQuantity || move.quantity,
      rate: move.rate,
      fromType: move.toType, // Current status becomes the fromType
      toType: toType,
      fromWarehouse: move.toWarehouse,
      toWarehouse: action === "TRANSFERRED" ? null : move.toWarehouse,
    };
    
    setInitialStocks([initialStock]);
    setDrawerOpen(true);
  };

  const getDrawerTitle = () => {
    switch (selectedAction) {
      case "USED":
        return "Mark Stock as Used";
      case "TRANSFERRED":
        return "Transfer Stock";
      case "WASTED":
        return "Mark Stock as Wasted";
      default:
        return "Stock Move Configuration";
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box>
      {stockMoves?.map((move) => (
        <StockMoveCard
          key={move._id}
          move={move}
          onDelete={deleteStockMove}
          onActionSelect={handleActionSelect}
        />
      ))}
      <StocksMoveConfigDrawer
        open={drawerOpen}
        setOpen={setDrawerOpen}
        selectedWarehouse={selectedMove?.toWarehouse}
        warehouses={warehouses}
        stockMoveMode={stockMoveMode}
        selectedMove={selectedMove}
        setShouldRefreshStocks={setShouldRefreshStocks}
        selectedOrganization={selectedOrganization}
        mainEntity={mainEntity}
        mainEntityProfileId={mainEntityProfileId}
        title={getDrawerTitle()}
        initialStocks={initialStocks}
        defaultFrom={selectedMove?.toType}
        defaultTo={initialStocks[0]?.toType}
      />
    </Box>
  );
};

export default InventoryStockMoves;
